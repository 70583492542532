import { Component } from "react";
import LayoutWrapper from "../../../LayoutWrapper";
import "../module.scss";
import AssessmentsDetailsPage from "./AssessmentsDetailsPage";

export class PreviewAssessmentsPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("PreviewAssessmentsPage");
    this.state = {
      data: [],
    };
  }

  get errors() {
    return Object.entries(this.errorsMessages)
      .filter(([key, val]) => val)
      .map(([key, val]) => key);
  }

  get isValid() {
    let data = this.formData;
    let keys = Object.keys(data);
    // let { fields = [] } = this.state.form || {};
    let fields = this.state.form || [];
    return keys.reduce((flag, key) => {
      let field = fields.find((o) => o.label == key || o.name == key) || {};
      let constraints = field.fieldConstraint
        .filter((v) => v)
        .map((v) => _enum.CONSTRAINTS_LMS_MAP[v]);
      let value = data[key];
      let valid = constraints.reduce(
        (flag, v) =>
          flag &&
          ((typeof v == "string" && value.match(new RegExp(v))) ||
            (typeof v == "function" &&
              v(field.fieldType == "date" ? value.date() : value))),
        true
      );
      if (!constraints.includes("mandatory") && !value) {
        valid = true;
      }
      if (!valid) {
        this.errorsMessages[key] = true;
      } else {
        this.errorsMessages[key] = false;
      }
      return flag && !!valid;
    }, true);
  }

  get formData() {
    return this.refs.form.form.current.getData();
  }

  componentDidMount() {
    this.fetchData();
    this.getBreadCums();
  }

  fetchData() {
    this.props.api
      .getTableAssessmentPreviewById({
        assessment_id: this.props.urlparams.assessment_id,
      })
      .then(({ data }) => {
        console.log(data);
        this.setState({ data: data });
      });
  }

  getBackLink() {
    let backLink = `/curriculum/sessions?grade=${
      this.props.urlparams.grade
    }&level=${this.props.urlparams.level.toUpperCase()}&type=${
      this.props.urlparams.type
    }&curriculum_id=${this.props.urlparams.curriculum_id}&class_id=${
      this.props.urlparams.class_id
    }`;
    return backLink;
  }

  getBreadCums() {
    if (this.props.urlparams.session_id) {
      let payload = {
        session_id: this.props.urlparams.session_id,
      };
      console.log(payload);
      this.props.api
        .apiGetContentSessionById({
          session_id: this.props.urlparams.session_id,
        })
        .then(({ data }) => {
          this.setState({
            sessionDetails: data,
          });

          let breadcrum = `Curriculum/${this.props.urlparams.grade} Grade - Level ${this.props.urlparams.level}/${this.props.urlparams.type}/${data.session_name} : Preview Assessment`;

          this.setState({
            breadcrum: breadcrum,
          });
        });
    } else {
      return "PPT";
    }
  }

  getTitle() {
    if (this.props.urlparams.grade) {
      let title = `${this.props.urlparams.grade} Grade - Level ${this.props.urlparams.level}`;
      return title;
    } else {
      return "Session";
    }
  }

  render() {
    let { form = [], data, breadcrum } = this.state;
    return (
      <LayoutWrapper
        title={this.getTitle()}
        back={breadcrum}
        backlink={this.getBackLink()}
      >
        <AssessmentsDetailsPage
          data={this.state.data}
          isPreview={this.props.urlparams?.isPreview}
        />
        <div className="d-flex justify-content-end">
        <button className="btn btn-success btn-block ms-2 px-4 text-end" onClick={()=>    this.props.navigate(this.getBackLink())}>Close</button>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(PreviewAssessmentsPage);
