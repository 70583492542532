import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import _enum from "src/mixins/enum";
import LayoutWrapper from "./LayoutWrapper";
import TaskModal from "./TaskModal";
import TaskSearch from "./TaskSearch";
import TaskKpi from "./TaskKpi";

export class TaskManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("TaskManagement");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 10,
        order_by: "createdAt",
        order_direction: "DESC",
        page: 1,
        search: {
          // assigned_schools: ["school_id"],
          dueIn: "ALL",
        },
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      loader: false,
      schools: [],
    };
    this.columns = [
      {
        dataIndex: "task_name",
        title: "Task Name",
      },
      {
        dataIndex: "schools_data",
        title: "School Name",
        render: (text, record) => {
          return text?.school_name;
        },
      },
      {
        dataIndex: "priority",
        title: "Priority",
        render: (text, record) => {
          return (
            <span
              className={`badge light border-0 ${
                text === "HIGH"
                  ? "text-danger"
                  : text === "MEDIUM"
                  ? "text-warning"
                  : "badge-success"
              } `}
            >
              {text?.toTitleCase()}
            </span>
          );
        },
      },
      {
        dataIndex: "due_date",
        title: "Due Date",
        render: (text, record) => {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        },
      },
      {
        dataIndex: "task_status",
        title: "Task Status",
        render: (text, record) => {
          let data = _enum.TASK_STATUS.find((task) => task.key === text);
          return (
            <span className={`badge light border-0 ${data?.color} `}>
              {data?.label}
            </span>
          );
        },
      },
      {
        dataIndex: "createdAt",
        title: "Created on",
        render: (text, record) => {
          let date = new Date(text).dateToDDMMYYYY(new Date(text));
          return date;
        },
      },
      {
        dataIndex: "updatedBy",
        title: "Updated By",
        render: (text, record) => {
          return text?.name;
        },
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "task_name",
        label: "Task Name",
        disabled: localStorage.getItem("currentAdminRole") !== "SUPER_ADMIN",
        customcolspan: "col-12",
        required: true,
      },
      {
        type: "select",
        name: "school_id",
        label: "School",
        multi: true,
        // options: ()=>{ return [{key:'test',label:'test'}]},
        required: true,
      },
      {
        type: "select",
        name: "priority",
        label: "Priority",
        options: _enum.PRIORITY,
        disabled: localStorage.getItem("currentAdminRole") !== "SUPER_ADMIN",
        required: true,
      },
      {
        type: "date",
        name: "due_date",
        disabled: localStorage.getItem("currentAdminRole") !== "SUPER_ADMIN",
        disabledDates: (date) => {
          return date.getTime() < new Date().toDate().date().getTime();
        },
        label: "Due Date",
        required: true,
      },
      {
        type: "select",
        name: "task_status",
        label: "Task Status",
        options: _enum.TASK_STATUS,
        required: true,
      },
      {
        type: "textarea",
        name: "comment_history",
        label: "Comment History",
        customcolspan: "col-12",
        disabled: true,
      },
      {
        type: "textarea",
        name: "comment",
        label: "Comment",
        customcolspan: "col-12",
        // required: true,
      },
      {
        type: "date",
        name: "createdAt",
        label: "Created Date",
        disabled: true,
      },
      {
        type: "date",
        name: "updatedAt",
        label: "Updated Date",
        disabled: true,
      },
    ];
    this.taskModalRef = React.createRef();
  }

  componentDidMount() {
    this.getSchoolNames();
    this.fetchList();
  }

  getSchoolNames() {
    let payload = {
      status: "ACTIVE",
    };
    if (localStorage.getItem("currentAdminRole") == "SUPER_ADMIN") {
      this.props.api
        .apiGetAllSchools(payload)
        .then(({ data, total }) => {
          let options = data[0].records?.options("school_id", "school_name");
          let schoolField = this.modalFields.find((o) => o.name == "school_id");
          schoolField.options = options;
          this.setState({
            schools: options,
          });
        })
        .catch((error) => {});
    }else{
       this.columns = this.columns.filter(
         (col) => col.dataIndex !== "schools_data"
       );
    }
  }
  addModalFields() {
     let fields = this.modalFields
       .filter(
         (field) =>
           ![
             "task_status",
             "comment_history",
             "updatedAt",
             "createdAt",
           ].includes(field.name)
       )
       .reduce((o, n) => ({ ...o, [n.name]: n }), {});
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchList(data) {
    // this.setState({
    //   loader: true,
    // });
    let payload = {
      ...data,
      ...this.state.search,
    };
    if (localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR") {
      let school_id = localStorage.getItem("school_id");
      this.setState({
        search: {
          ...this.state.search,
          search: {
            ...this.state.search.search,
            assigned_schools: [school_id],
          },
        },
      });
      payload = {
        ...payload,
        search: {
          ...(payload.search?.dueIn === "2%2B" ? {
            ...payload.search,
            dueIn: "2+"
          } : {
            ...payload.search,
          }),
          assigned_schools: [school_id],
        },
      };
    }
    
    payload = {
      ...payload,
      search: {
        ...(payload.search?.dueIn === "2%2B" ? {
          ...payload.search,
          dueIn: "2+"
        } : {
          ...payload.search,
        }),
      },
    };
    
    this.props.api
      .apiGetAllTasks(payload)
      .then(({ data, total }) => {
        this.setState({
          data: data,
          rowcount: total,
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }

  downloadFile() {}

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  deleteAction(value) {
    let payload = {
      task_id: value.task_id,
    };
    confirm(`Do you really want to delete these task?`).promise.then(() => {
      this.api.apiDeleteTask(payload).then(() => {
        toast.success("Task deleted Successfully");
        this.fetchList();
      });
    });
  }

  editAction(v) {
    // console.log("edit: ", v);
    this.openTaskModal();
    this.getSchoolNames();
    let data = Object.except(v, [
      "profile_id",
      "createdAt",
      "updatedAt",
      "is_password_updated",
      "role_id",
      "user_id",
      "password",
      "temp_pass",
      "assigned_schools_datas",
      "last_updated_by",
      "created_by",
      "updatedBy",
      "createdBy",
      "is_active",
      "assigned_schools",
      "schools_data",
      "group_id",
      "comments",
      "task_id",
    ]);
    data.comment = "";
    data.comment_history = v.comments
      ?.map(
        (comment) =>
          `[${comment.created_by_name} - ${new Date(
            comment.updatedAt
          ).toLocaleString()}] ${comment.comment}`
      )
      .reverse()
      .join("\n");
    data.createdAt = v.createdAt;
    data.updatedAt = v.updatedAt;
    let fields;
    if (localStorage.getItem("currentAdminRole") == "SUPER_ADMIN") {
      data.school_id = v?.assigned_schools_datas;
      let schoolField = this.modalFields.find((o) => o.name == "school_id");
      schoolField.options = this.state.schools;
      schoolField.multi = false;
      schoolField.defaultValue = v.school_id;
      schoolField.disabled = true;
      fields = this.modalFields.reduce((o, n) => ({ ...o, [n.name]: n }), {});
    } else {
      let schoolField = this.modalFields.find((o) => o.name == "school_id");
      schoolField.type = "text";
      schoolField.defaultValue = v.schools_data?.school_name;
      schoolField.disabled = true;
      fields = this.modalFields
        .reduce((o, n) => ({ ...o, [n.name]: n }), {});
    }

    this.setState({
      popupfields: fields,
      popupdata: data,
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }

  viewAction(v) {
    console.log("view: ", v);
    this.openTaskModal();
    let data = Object.except(v, [
      "profile_id",
      "createdAt",
      "updatedAt",
      "createdBy",
      "updatedBy",
      "is_password_updated",
      "role_id",
      "user_id",
      "password",
      "temp_pass",
      "assigned_schools_datas",
      "last_updated_by",
      "created_by",
      "schools_data",
      "group_id",
      "task_id",
      "assigned_schools",
      "is_active",
      "comments"
    ]);
    data.last_updated_by = v.updatedBy?.name;
    data.created_on = new Date(v?.createdAt).dateToDDMMYYYY(new Date(v?.createdAt));
    data.comment = v.comments
      ?.map(
        (comment) =>
          `[${comment.created_by_name} - ${new Date(
            comment.updatedAt
          ).toLocaleString()}] ${comment.comment}`
      )
      .reverse()
      .join("\n");
 let fields = this.modalFields
   .filter(
     (field) =>
       !["comment_history"].includes(
         field.name
       )
   )
   .reduce((o, n) => ({ ...o, [n.name]: n }), {});

    this.setState({
      popupdata: data,
      popupfields: fields,
      editMode: false,
      viewMode: true,
      editData: v,
    });
  }

  onSearch(data) {
    // console.log("onSearch data: ", data);
    this.setState(
      {
        search: {
          ...this.state.search,
          search: data,
        },
      },
      () => {
        this.fetchList();
      }
    );
  }

  openTaskModal() {
    this.taskModalRef.current.click();
  }
  closeModal() {
    document.querySelector("#add_task_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();

    this.setState({
      loader: true,
    });

    const resolveList = () => {
      toast.success(
        `${
          this.state.editMode
            ? "Task Updated successfully"
            : "Task added successfully"
        }`,
        {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
        }
      );
      form.reset();
      this.closeModal();
      this.fetchList();
    };
    if (this.state.editMode) {
      let payload = Object.only(data, [
        "task_name",
        "priority",
        "due_date",
        "comment",
        "description",
        "task_status",
      ]);
      if (localStorage.getItem("currentAdminRole") !== "SUPER_ADMIN")
        payload = {
          task_status: data?.task_status,
          comment: data?.comment,
        };
    
      this.props.api
        .apiUpdateTask(payload, { task_id: this.state.editData.task_id })
        .then(resolveList)
        .then(() => {
          this.setState({
            loader: false,
          });
        })
        .catch(() => {
          this.setState({
            loader: false,
          });
        });
    } else {
      let payload = {
        ...data,
        assigned_schools: data.school_id.split(","),
      };
      delete payload.school_id;
      // delete payload.task_status;
      this.props.api
        .apiAddTask(payload)
        .then(resolveList)
        .then(() => {
          this.setState({
            loader: false,
          });
        })
        .catch((error) => {
          this.setState({
            loader: false,
          });
        });
    }
  }

  updateFields(fields) {
    return fields;
  }
  render() {
    let {
      data = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      loader,
      search
    } = this.state;
    let { columns, modalFields } = this;
    modalFields = this.updateFields(modalFields);
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper title="Task Management" back="">
          <ToastContainer position="top-right" />
          <div className="row">
            <div className="col"></div>
            <div className="mb-4">
              <Link
                className={Object.className({
                  "btn add-btn ": true,
                  hidden:
                    localStorage.getItem("currentAdminRole") !== "SUPER_ADMIN",
                })}
                l
                ref={this.taskModalRef}
                data-bs-toggle="modal"
                data-bs-target="#add_task_modal"
                onClick={(...args) => this.addModalFields(...args)}
              >
                <>
                  <i className="fa fa-plus"></i>
                  Add Task
                </>
              </Link>
            </div>
          </div>
          <TaskSearch
            onSearch={(...arg) => this.onSearch(...arg)}
            schoolsData={this.state.schools}
          />
          <TaskKpi 
          school_id={search?.search?.assigned_schools} 
          priority={search?.search?.priority}
          taskStatus={search?.search?.task_status}
          dueIn={search?.search?.dueIn}
          />
          <div className="row schoolmanagement">
            <div className="col-12">
              {!!data.length && (
                <AppTable
                  data={data}
                  columns={columns}
                  onNext={() => this.onNext()}
                  onPrev={() => this.onPrev()}
                  onChange={(...arg) => this.onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  deleteAction={(v) => this.deleteAction(v)}
                  isRowDeletable={() =>
                    localStorage.getItem("currentAdminRole") == "SUPER_ADMIN"
                  }
                  editAction={(v) => this.editAction(v)}
                  viewAction={(v) => this.viewAction(v)}
                  targetType="tap"
                  isresponsive={true}
                ></AppTable>
              )}
              {data.length == 0 && <div className="empty_layout"></div>}
            </div>
          </div>
          <TaskModal
            id="add_task_modal"
            title={`${
              editMode ? "Update Task" : viewMode ? "View Task" : "Add Task"
            }`}
            submitText={`${editMode ? "Update" : "Save"}`}
            editdata={editData}
            data={popupdata}
            fields={popupfields}
            disableallfields={viewMode ? true : false}
            submitButton={viewMode ? false : true}
            closeButton={viewMode ? true : false}
            onSubmit={(...args) => this.handleSubmit(...args)}
            onClose={() =>
              this.setState({
                popupdata: {},
                editData: {},
                editMode: false,
                viewMode: false,
              })
            }
          />
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(TaskManagement);
