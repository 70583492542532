import moment from "moment";
import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import _enum from "src/mixins/enum";
import DeleteEventModal from "./DeleteEventModal";
import LayoutWrapper from "./LayoutWrapper";
import "./schedulemanagement.scss";
import ScheduleSearch from "./ScheduleSearch";
import { event } from "jquery";

const localizer = momentLocalizer(moment);

export class AppCalender extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AppCalender");
    this.handleEventClick = this.handleEventClick.bind(this);
    this.editEventModalRef = React.createRef();
    this.deleteEventModalRef = React.createRef();

    this.state = {
      popupdata: {},
      popupfields: {},
      events: [],
      selectedMonth: "",
      view: "month",
      date: new Date(),
      yearsArr: [],
      current: {
        year: moment().year(),
        month: moment().month(),
        day: moment().date(),
      },
      editMode: false,
      classId: "",
      eventId: "",
      search: {
        event_status: "",
        school_id: undefined,
      },
      eventToDelete: null,
      schoolId: window.store.getState().root.school_id,
    };

    this.modalFields = [
      {
        type: "text",
        name: "topic",
        label: "Topic",
        required: true,
      },
      {
        type: "select",
        name: "event_status",
        label: "Status",
        options: _enum.EVENT_STATUS,
        required: true,
      },
      {
        type: "date",
        name: "event_date",
        label: "Date",
        disabledDates: (date) => {
          return date.getTime() < new Date().toDate().date().getTime();
        },
        // onChange: (data) => {
        //   this.handleEventDateChange(data);
        // },
        required: true,
      },
      {
        type: "text",
        name: "school_id",
        label: "School",
        disabled: true,
      },
      {
        type: "text",
        name: "school_coordinator",
        label: "School Coordinator",
        disabled: true,
      },
      {
        type: "text",
        name: "topic",
        label: "Topic",
        disabled: true,
      },
      {
        type: "time",
        name: "event_time",
        label: "Time",
        // onChange: (e) => {
        //   this.handleTimeChange(e);
        // },
        required: true,
      },
    ];
    this.dateFields = {
      type: "date",
      name: "date",
      defaultValue: new Date(),
      placeholder: "Choose Month",
      className: "date-select",
      onChange: (...args) => this.handleDateChange(...args),
    };

    this.monthFields = {
      type: "select",
      name: "months",
      placeholder: "Choose Month",
      defaultValue: _enum.MONTHS.find(
        (month) => month.index - 1 === new Date().getMonth()
      ).key,
      options: _enum.MONTHS,
      className: "month-select",
      onChange: (...args) => this.handleMonthChange(...args),
    };
    this.yearFields = {
      type: "select",
      name: "year",
      placeholder: "Choose Year",
      defaultValue: new Date().getFullYear(),
      options: [
        { key: moment().year() - 9, label: moment().year() - 9, index: 9 },
        { key: moment().year() - 8, label: moment().year() - 8, index: 8 },
        { key: moment().year() - 7, label: moment().year() - 7, index: 7 },
        { key: moment().year() - 6, label: moment().year() - 6, index: 6 },
        { key: moment().year() - 5, label: moment().year() - 5, index: 5 },
        { key: moment().year() - 4, label: moment().year() - 4, index: 4 },
        { key: moment().year() - 3, label: moment().year() - 3, index: 3 },
        { key: moment().year() - 2, label: moment().year() - 2, index: 2 },
        { key: moment().year() - 1, label: moment().year() - 1, index: 1 },
        { key: moment().year(), label: moment().year(), index: 10 },
        { key: moment().year() + 1, label: moment().year() + 1, index: 11 },
        { key: moment().year() + 2, label: moment().year() + 2, index: 12 },
        { key: moment().year() + 3, label: moment().year() + 3, index: 13 },
        { key: moment().year() + 4, label: moment().year() + 4, index: 14 },
        { key: moment().year() + 5, label: moment().year() + 5, index: 15 },
        { key: moment().year() + 6, label: moment().year() + 6, index: 16 },
        { key: moment().year() + 7, label: moment().year() + 7, index: 17 },
        { key: moment().year() + 8, label: moment().year() + 8, index: 18 },
        { key: moment().year() + 9, label: moment().year() + 9, index: 19 },
      ],
      className: "month-select",
      onChange: (...args) => this.handleYearChange(...args),
    };
  }

  componentDidMount() {
    this.fetchEvents();
  }

  addModalFields() {
    this.props.navigate(`/schedulemanagement/addevent`);
  }

  async fetchEvents() {
    let payload = {
      ...this.state.search,
      ...this.state.current,
      // ...this.state.date,
      month: this.state.current.month + 1,
    };
    payload && delete payload.day;
    if (localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR") {
      if (payload.event_status.length > 0) {
        payload.event_status = payload.event_status;
      } else {
        delete payload.event_status;
      }
      payload.school_id = localStorage.getItem("school_id");
    }
    try {
      const { data } =
        localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR"
          ? await this.props.api.apiGetAllSchoolEvent(
              Object.except(payload, ["school_id"]),
              {
                school_id: localStorage.getItem("school_id"),
              }
            )
          : await this.props.api.apiGetAllEvents(payload);
      let newEventData = await Promise.all(
        data.map(async (event) => {
          let iso8601String = event.event_date;
          let startDate = new Date(iso8601String);
          let eventTime = event.event_time?.splitBy(":");

          startDate.setHours(eventTime?.[0]);
          startDate.setMinutes(startDate.getMinutes() - 30);
          const endDate = new Date(startDate);
          endDate.setMinutes(endDate.getMinutes() + 60);

          return {
            ...event,
            id: event._id,
            event_id: event.event_id,
            title: event.topic || "R_Event",
            start: startDate,
            end: endDate,
            allDay: false,
            resource: "",
          };
        })
      );
      this.setState({
        events: newEventData,
      });
    } catch (error) {
      console.log({ error: error });
      this.setState({
        loader: false,
      });
    }
  }

  toastConfig(text, message) {
    const options = {
      autoClose: 3000,
      closeButton: true,
      hideProgressBar: true,
    };

    if (message === "success") {
      toast.success(text, options);
    } else {
      toast.error(text, options);
    }
  }

  handleViewChange = (view) => {
    this.setState(
      {
        view: view,
        date: moment(new Date()),
      },
      () => {
        if (view === "month") {
          this.fetchEvents();
        }
      }
    );
    // You can perform additional actions here based on the view change
  };

  handleMonthChange = (e) => {
    const selectedMonth = e[0].index;
    let obj = {
      ...this.state.current,
    };
    obj.month = selectedMonth - 1;
    this.setState(
      {
        date: moment(obj),
        current: obj,
      },
      () => {
        this.fetchEvents();
      }
    );
    console.log(`Selected month: ${selectedMonth}`);
  };

  handleYearChange = (e) => {
    const selectedYear = e[0].key;
    let obj = {
      ...this.state.current,
    };
    obj.year = selectedYear;
    this.setState(
      {
        date: moment(obj),
        current: obj,
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  handleDateChange = (e) => {
    let obj = {
      ...this.state.current,
    };

    obj.day = moment(e).date();
    obj.month = moment(e).month();
    obj.year = moment(e).year();
    this.setState({
      date: moment(obj),
      current: obj,
    });
  };

  onSearch(data) {
    console.log({ data });
    if (Object.keys(data).length > 0) {
      this.setState(
        {
          search: {
            ...this.state.search,
            ...data,
          },
        },
        () => this.fetchEvents()
      );
    } else {
      this.setState(
        {
          search: {
            event_status: "",
            school_id: undefined,
          },
        },
        () => this.fetchEvents()
      );
    }
  }

  EventComponent = ({ event }) => {
    return (
      <div className="e-flex justify-content-between align-items-center">
        <span
          className="event-title"
          onClick={() => {
            this.handleEventClick(event);
          }}
        >
          {event.title}
        </span>

        <div>
          {event.is_recurrent && <i className="fas fa-arrows-rotate m-1"></i>}
          {((localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR" &&
            ["OPEN"].includes(event.event_status)) ||
            localStorage.getItem("currentAdminRole") === "SUPER_ADMIN") && (
            <a
              onClick={(e) =>
                this.setState(
                  {
                    eventToDelete: event,
                  },
                  () => {
                    document.querySelector("#delete_event").click();
                  }
                )
              }
            >
              <i className="fa fa-trash-o m-r-5" />
            </a>
          )}
        </div>
      </div>
    );
  };

  eventStyleGetter = (event) => {
    let newStyle = {
      borderRadius: "5px",
    };
    if (event.event_status === "CANCELLED") {
      newStyle = {
        ...newStyle,
        backgroundColor: "#F4D3CC",
        color: "#F53D15",
        border: "1px solid #F53D15",
      };
    } else if (["OPEN", "SCHEDULED"].includes(event.event_status)) {
      newStyle = {
        ...newStyle,
        backgroundColor: "#E2E3E3",
        color: "#878383",
        border: "1px solid #878383",
      };
    } else if (event.event_status === "COMPLETED") {
      newStyle = {
        ...newStyle,
        backgroundColor: "#ECFDF5",
        color: "#64C391",
        border: "1px solid #64C391",
      };
    }
    return {
      style: newStyle,
    };
  };

  handleDeleteEvent = (payload) => {
    this.props.api
      .apiDeleteEventSuper(
        { event_id: this.state.eventToDelete.event_id },
        payload
      )
      .then((data) => {
        this.toastConfig("Event deleted successfully", "success");
        this.fetchEvents();
      })
      .catch((err) => {
        this.toastConfig("Failed to delete event", "error");
      });
  };

  handleEventClick(data) {
    if (
      localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR" &&
      ["COMPLETED", "CANCELLED"].includes(data.event_status)
    ) {
      sessionStorage.setItem("eventData", JSON.stringify(data?.event_id));
      this.props.navigate(`/schedulemanagement/editevent?readonly=true`);
    } else {
      sessionStorage.setItem("eventData", JSON.stringify(data?.event_id));
      this.props.navigate(`/schedulemanagement/editevent`);
    }
  }

  handleEditSubmit(e, data) {
    e.preventDefault();
    let parsedPayload = parseInput(data) || [];
    let payload = {
      // ...this.state.eventId,
      ...parsedPayload,
    };
    payload.school_coordinator && delete payload.school_coordinator;
    payload.school_id && delete payload.school_id;

    this.props.api
      .apiUpdateEventSuper({ event_id: this.state.eventId?.event_id }, payload)
      .then((data) => {
        this.toastConfig("Event Updated successfully", "success");
        this.fetchEvents();
        document.querySelector("#edit_event_modal #bsmodalclose").click();
      })
      .catch((err) => {
        this.toastConfig("Failed to update event", "error");
      });
  }

  render() {
    let {
      events,
      popupdata,
      popupfields,
      selectedMonth,
      date,
      view,
      yearFields,
      editMode,
      addMode,
      eventToDelete,
    } = this.state;

    return (
      <>
        <LayoutWrapper title="Schedule Management" back="Schedule Management">
          <div>
            <ToastContainer position="top-right" />
            <div className="row"></div>
            <ScheduleSearch
              onSearch={(...arg) => this.onSearch(...arg)}
              addModalFields={(...args) => this.addModalFields(...args)}
            />
            {view != "day" && (
              <div>
                <AppInput {...this.monthFields}></AppInput>
                <AppInput {...this.yearFields}></AppInput>
              </div>
            )}
            {view == "day" && (
              <div>
                <AppInput
                  {...this.dateFields}
                  defaultValue={moment(this.state.date)}
                ></AppInput>
              </div>
            )}
            <Calendar
              localizer={localizer}
              defaultDate={new Date()}
              date={date}
              events={events}
              className="super_calendar"
              startAccessor="start"
              endAccessor="end"
              tooltipAccessor="label"
              style={{ height: 700, width: "100%" }}
              toolbar={true}
              defaultView={"month"}
              view={view}
              onView={this.handleViewChange}
              components={{
                event: this.EventComponent, // Use custom event component
              }}
              eventPropGetter={this.eventStyleGetter}
              // onSelectEvent={(args) => this.handleEventClick(args)}
              onShowMore={(events, date) => {
                let obj = {
                  ...this.state.current,
                };
                obj.day = moment(date).date();
                obj.month = moment(date).month();
                obj.year = moment(date).year();
                this.setState({
                  view: "day",
                  date: moment(obj),
                  current: obj,
                  events: events,
                });
              }}
            />
            <DeleteEventModal
              id="delete_event_modal"
              title={""}
              submitText={editMode ? "Update" : "Save"}
              data={eventToDelete}
              fields={popupfields}
              submitButton={true}
              onSubmit={(...args) => this.handleDeleteEvent(...args)}
              onClose={() => {}}
            />
            {/* below button hidden on UI */}
            <button
              id="fetch_event"
              className="btn btn-sm btn-outline-secondary d-none"
              ref={this.editEventModalRef}
              data-bs-toggle="modal"
              data-bs-target="#edit_event_modal"
            >
              <span>Hidden Button</span>
            </button>
            <button
              id="delete_event"
              className="btn btn-sm btn-outline-secondary d-none"
              ref={this.deleteEventModalRef}
              data-bs-toggle="modal"
              data-bs-target="#delete_event_modal"
            >
              <span>Hidden Button</span>
            </button>
          </div>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(AppCalender);
