import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import LayoutWrapper from "../../../LayoutWrapper";
import "../module.scss";
import AssessmentSearch from "./AssessmentSearch";
import PhotoModal from "./PhotoModal";

export class AssessmentsPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AssessmentsPage");
    this.state = {
      data: [],
      form: [],
      rowcount: 0,
      errors: {},
      breadcrum: "",
      currentAssessment: {},
      selectedProfiles:null,
      tablinks: [],
      assessment_answers: [],
      selectedQuestion: null,
    };
    this.errorsMessages = {};
    this.photoModalRef = React.createRef();
  }

  get errors() {
    return Object.entries(this.errorsMessages)
      .filter(([key, val]) => val)
      .map(([key, val]) => key);
  }

  get isValid() {
    let data = this.formData;
    let keys = Object.keys(data);
    // let { fields = [] } = this.state.form || {};
    let fields = this.state.form || [];
    return keys.reduce((flag, key) => {
      let field = fields.find((o) => o.label == key || o.name == key) || {};
      let constraints = field.fieldConstraint
        .filter((v) => v)
        .map((v) => _enum.CONSTRAINTS_LMS_MAP[v]);
      let value = data[key];
      let valid = constraints.reduce(
        (flag, v) =>
          flag &&
          ((typeof v == "string" && value.match(new RegExp(v))) ||
            (typeof v == "function" &&
              v(field.fieldType == "date" ? value.date() : value))),
        true
      );
      if (!constraints.includes("mandatory") && !value) {
        valid = true;
      }
      if (!valid) {
        this.errorsMessages[key] = true;
      } else {
        this.errorsMessages[key] = false;
      }
      return flag && !!valid;
    }, true);
  }

  get formData() {
    return this.refs.form.form.current.getData();
  }

  componentDidMount() {
    this.fetchData();
    this.getBreadCums();
  }

  async fetchData() {
    try {
      if (this.props.urlparams.session_id) {
        localStorage.setItem("session_id", this.props.urlparams.session_id);
        const { data } = await this.props.api.apiGetAssessments({
          session_id: this.props.urlparams.session_id,
        });

        const assessmentData = [];

        for (const item of data) {
          const assessmentResponse = await this.props.api.apiGetAssessmentById({
            assessment_id: item.assessment_id,
          });
          item.assessment_questions =
            assessmentResponse.data?.assessment_questions;
          assessmentData.push(item);
        }

        this.setState({
          form: assessmentData,
          data: assessmentData,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  }

  onSearch(data) {
    console.log("onSearch data: ", data);
    // this.setState({
    //   search: data
    // }, () => {
    //   this.fetchList(data);
    // });
  }

  handleClose(e, values) {
    e.preventDefault();
    e.stopPropagation();
    let closeLink = `/curriculum/sessions?grade=${
      this.props.urlparams.grade
    }&level=${this.props.urlparams.level.toUpperCase()}&type=${
      this.props.urlparams.type
    }&curriculum_id=${this.props.urlparams.curriculum_id}&class_id=${
      this.props.urlparams.class_id
    }`;

    const lastIndex = closeLink.lastIndexOf("?");
    const correctedCloseLink =
      closeLink.slice(0, lastIndex) + closeLink.slice(lastIndex + 1);
    // console.log("This is close link", correctedCloseLink);

    this.props.navigate(correctedCloseLink);
  }

  getBackLink() {
    let backLink = `/curriculum/sessions?grade=${
      this.props.urlparams.grade
    }&level=${this.props.urlparams.level.toUpperCase()}&type=${
      this.props.urlparams.type
    }&curriculum_id=${this.props.urlparams.curriculum_id}&class_id=${
      this.props.urlparams.class_id
    }`;
    return backLink;
  }

  onInputChange(field, event, ...args) {
    let value = "";
    if (event?.nativeEvent instanceof InputEvent) {
      value = event.target.value;
    } else if (event instanceof String) {
      value = event;
    } else if (event instanceof Number) {
      value = "" + event;
    }

    // let constraints = field.fieldConstraint
    //   // .split(",")
    //   .map((v) => _enum.CONSTRAINTS_LMS_MAP[v]);
    // let valid = constraints.reduce(
    //   (flag, v) => flag && value.match(new RegExp(v)),
    //   true
    // );
    // if (!valid) {
    // }
    // app.mixin.debounce(() => {
    //   this.setState({
    //     errors: {
    //       ...this.state.errors,
    //       [field.label]: !valid,
    //     },
    //   });
    // }, 500);
  }

  // onFieldFocus(field) {
  //   console.log(field);
  // }

  isCheckboxOrRadio(type) {
    return ["checkbox", "radio"].includes(type);
  }

  linkGeneration(attach_file) {
    let authToken = window.store.getState().root.auth;
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: attach_file }).url +
      "?auth=" +
      authToken
    );
  }

  getBreadCums() {
    if (this.props.urlparams.session_id) {
      let payload = {
        session_id: this.props.urlparams.session_id,
      };
      console.log(payload);
      this.props.api
        .apiGetContentSessionById({
          session_id: this.props.urlparams.session_id,
        })
        .then(({ data }) => {
          this.setState({
            sessionDetails: data,
          });

          let breadcrum = `Curriculum/${this.props.urlparams.grade} Grade - Level ${this.props.urlparams.level}/${this.props.urlparams.type}/${data.session_name} : ASSESSMENT`;

          this.setState({
            breadcrum: breadcrum,
          });
        });
    } else {
      return "PPT";
    }
  }
  getTitle() {
    if (this.props.urlparams.grade) {
      let title = `${this.props.urlparams.grade} Grade - Level ${this.props.urlparams.level}`;
      return title;
    } else {
      return "Session";
    }
  }
  handleCurrentAssessment(item) {
    this.setState({
      currentAssessment: item,
    });
    console.log(this.state.currentAssessment);
  }
  submitForm(e, data, form, field) {
    // if (!this.isValid) {
    //   alert("Please fill valid values in " + this.errors.join(","));
    //   return;
    // }
    // let parsedPayload = parseInput(data);

    let assessment_answers = Object.entries(form.getData()).map(
      ([key, value]) => ({
        question_id: key,
        student_answer: value,
      })
    );

    let payload = {
      ...{
        session_id: this.props.urlparams.session_id,
        assessment_id: this.state.currentAssessment.assessment_id,
      },
      ...{ assessment_answers: assessment_answers },
      profiles:this.state.selectedProfiles
    };

    this.props.api.apiSubmitAssessment(payload).then((response) => {
      success("Your Assessment Submitted Successfully");
      let backLink = `/curriculum/sessionsview/module/previewassessment?grade=${
        this.props.urlparams.grade
      }&level=${this.props.urlparams.level.toUpperCase()}&type=${
        this.props.urlparams.type
      }&curriculum_id=${this.props.urlparams.curriculum_id}&class_id=${
        this.props.urlparams.class_id
      }&session_id=${this.props.urlparams.session_id}&assessment_id=${payload?.assessment_id}&isPreview=true`;
          this.props.navigate(backLink);
    })
    // let closeLink = "/lms/courses";
  }

  startAssessment(payload) {
    this.props.api
      .apiStartAssessment(payload)
      .then(({ data }) => {
        this.setState({
          selectedProfiles:payload.map(p=>p.profile_id),
          currentAssessment:
            this.state.data?.find(
              (assmt) => assmt.assessment_id === payload[0].assessment_id
            ) || {},
        });
        toast.success("Assessment Started!!");
      })
      .catch((error) => {
        toast.error("Unable to start assessment");
      });
  }
  render() {
    let { data = [], form = [], breadcrum, currentAssessment } = this.state;
    // let { tablinks } = this;
    console.log("tablinks----", form);
    console.log("This is currentData ", this.state.data);
    console.log("this is currentAssessment", this.state.currentAssessment);
    return (
      <LayoutWrapper
        title={this.getTitle()}
        back={breadcrum}
        backlink={this.getBackLink()}
      >
        <ToastContainer position="top-right" />

        <AssessmentSearch
          startAssessment={(data) => this.startAssessment(data)}
          assessmentData={data}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        {/* {form?.map((item, i) => {
          return (
            <button
              key={i}
              onClick={() => {
                this.handleCurrentAssessment(item);
              }}
              className={`btn rounded-full border mx-1 min-w-[150px] m-clip ${
                currentAssessment.assessment_id === item.assessment_id
                  ? "tab-active"
                  : ""
              }`}
            >
              {item.assessment_name}
            </button>
          );
        })} */}

        {/* <AppLinkTabs data={form} className="mb-0"></AppLinkTabs> */}
        <div className="d-flex align-items-center justify-content-center form_outer">
          {currentAssessment.assessment_id ? (
            <AppForm
              className="m-auto p-2 w-full custom_form"
              onSubmit={(...args) => this.submitForm(...args)}
              ref="form"
            >
              <div key={currentAssessment.assessment_id} className="grow">
                {currentAssessment?.assessment_questions?.map((ques, j) => (
                  <div>
                    {this.isCheckboxOrRadio(ques.q_type) && (
                      <div className="list-group option_box">
                        {ques.attach_file_type ? (
                          <AppInput
                            type="link"
                            ref={this.photoModalRef}
                            data-bs-toggle="modal"
                            data-bs-target="#photo_modal"
                            id={j}
                            label={`${j + 1}. ${ques.q_label}`}
                            linkLabel={`${
                              ques.attach_file_type == "VIDEO"
                                ? "Play Video"
                                : "View Image"
                            }`}
                            value={ques}
                            onLinkClick={(props) => {
                              if (!this.state.selectedQuestion) {
                                this.setState({
                                  selectedQuestion: {
                                    ...props,
                                    src:
                                      props.attach_file_type == "IMAGE"
                                        ? this.linkGeneration(props.attach_file)
                                        : "",
                                  },
                                });
                                this.photoModalRef.current.click();
                              }
                            }}
                          />
                        ) : (
                          <label className="col-form-label">
                            {j + 1}. {ques.q_label}
                          </label>
                        )}
                        {ques.q_options.map((option, k) => (
                          <AppInput
                            key={"option_" + "-" + k}
                            type={ques.q_type}
                            className="list-group-item"
                            name={ques.question_id}
                            value={option}
                            disabled={
                              localStorage.getItem("currentAdminRole") ==
                              "SCHOOL_COORDINATOR"
                            }
                          >
                            {ques.q_type === "checkbox" && option}
                          </AppInput>
                        ))}
                      </div>
                    )}
                    {!this.isCheckboxOrRadio(ques.q_type) && (
                      <div>
                        <AppInput
                          title={ques.q_label}
                          type={ques.q_type}
                          name={ques.question_id}
                          label={`${j + 1}. ${ques.q_label}`}
                          className="list-group option_box"
                          disabled={
                            localStorage.getItem("currentAdminRole") ==
                            "SCHOOL_COORDINATOR"
                          }
                          {...(ques.q_type == "date"
                            ? {
                                disabledDates: (date) =>
                                  this.handelDisabledDates(date, ques),
                              }
                            : {})}
                          {...(ques.q_type == "file"
                            ? {
                                fileType: "data_collection_form",
                              }
                            : {})}
                          options={
                            ques.q_options
                              ? ques.q_options
                                  // .split(",")
                                  .options("", "", (v) => v)
                              : []
                          }
                          // required={field.fieldConstraint.includes("mandatory")}
                          // onChange={(...args) =>
                          //   this.onInputChange(ques, ...args)
                          // }
                        ></AppInput>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <PhotoModal
                ref={this.photoModalRef}
                id="photo_modal"
                title=""
                data={this.state.selectedQuestion}
                className="photo_modal"
                onClose={() => {
                  this.setState({
                    selectedQuestion: null,
                  });
                }}
              ></PhotoModal>
              <div className="mt-4 flex justify-content-end">
                <button type={"submit"}
                  className="btn add-btn col col-md-1 ml-auto"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={(...args) => this.handleClose(...args)}
                  className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2"
                >
                  Cancel
                </button>
              </div>
            </AppForm>
          ) : (
            <div className="empty_assessment_list_layout"></div>
          )}
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(AssessmentsPage);
