export default {
  createClass: {
    url: "/api/v1/content/curriculum",
    method: "post",
  },
  getClasses: {
    url: "/api/v1/content/curriculum",
    method: "get",
  },
  getClassById: {
    url: "/api/v1/class/id/{class_id}",
    method: "get",
  },
  updateClass: {
    url: "/api/v1/class/{class_id}",
    method: "put",
  },
  deleteClass: {
    url: "/api/v1/class/{class_id}",
    method: "delete",
  },
  fileuploader: {
    url: "/api/v1/content/upload",
    method: "post",
  },
  fileDownloader: {
    url: "/api/v1/content/upload/{attachmentId}",
    method: "get",
  },
  filedeleter: {
    url: "/api/v1/content/upload/{attachmentId}",
    method: "delete",
  },
  filegetter: {
    url: "/api/v1/content/upload/thumbnail/{attachmentId}",
    method: "get",
  },
  // SESSION
  changeSessionAccess: {
    url: "/api/v1/access/session",
    method: "put",
  },
  createSession: {
    url: "/api/v1/content/session",
    method: "post",
  },
  getAllSessions: {
    url: "/api/v1/content/session/{curriculum_id}",
    method: "get",
  },
  getSessionById: {
    url: "/api/v1/content/session/id/{session_id}",
    method: "get",
  },
  getSessions: {
    url: "/api/v2/session/{session_id}",
    method: "get",
  },
  updateSession: {
    url: "/api/v1/content/session/{session_id}",
    method: "put",
  },
  deleteSession: {
    url: "/api/v1/content/session/{session_id}",
    method: "delete",
  },
  // PPT
  createPPT: {
    url: "/api/v1/content/ppt",
    method: "post",
  },
  getAllPPTById: {
    url: "/api/v1/content/ppt/session/{session_id}",
    method: "get",
  },
  getPPT: {
    url: "/api/v1/content/ppt/{ppt_id}",
    method: "get",
  },
  updatePPT: {
    url: "/api/v1/content/ppt/{ppt_id}",
    method: "put",
  },
  deletePPT: {
    url: "/api/v1/content/ppt/{ppt_id}",
    method: "delete",
  },
  // HANDOUTS
  deleteHandout: {
    url: "/api/v1/content/handout/{handout_id}",
    method: "delete",
  },
  // VIDEO
  createVideo: {
    url: "/api/v1/content/video/",
    method: "post",
  },
  getVideos: {
    url: "/api/v1/content/video/session/{session_id}",
    method: "get",
  },
  deleteVideo: {
    url: "/api/v1/content/video/{video_id}",
    method: "delete",
  },
  recordProgress: {
    url: "/api/v1/school/progress/record",
    method: "post",
  },

  // ASSESSMENTS
  createAssessment: {
    url: "/api/v1/content/assessment/{session_id}",
    method: "post",
  },
  getAssessments: {
    url: "/api/v1/content/assessment/{session_id}",
    method: "get",
  },
  getAssessmentById: {
    url: "/api/v1/content/assessment/id/{assessment_id}",
    method: "get",
  },
  updateAssessment: {
    url: "/api/v1/content/assessment/{assessment_id}",
    method: "put",
  },
  deleteAssessment: {
    url: "/api/v1/content/assessment/{assessment_id}",
    method: "delete",
  },
  addAssessmentQuestion: {
    url: "/api/v1/content/assessment/question/{assessment_id}",
    method: "post",
  },
  getAssessmentQuestion: {
    url: "/api/v1/content/assessment/question/{question_id}/{assessment_id}",
    method: "post",
  },
  updateAssessmentQuestion: {
    url: "/api/v1/content/assessment/question/{question_id}/{assessment_id}",
    method: "put",
  },
  deleteAssessmentQuestion: {
    url: "/api/v1/content/assessment/question/{assessment_id}/{question_id}",
    method: "delete",
  },

  // STUDENT API
  student_and_school_coordinator_login: {
    url: "/api/v1/student/auth/login",
    method: "post",
  },
  get_student_and_school_coordinator_info: {
    url: "/api/v1/student/auth/me",
    method: "get",
  },
  submitAssessment: {
    url: "/api/v1/student/assessment/submit",
    method: "post",
  },
  getAssessmentHistory: {
    url: "/api/v1/student/assessment/history/{session_id}",
    method: "get",
  },
  startAssessment:{
    url: "/api/v2/student/filter/start/",
    method: "post",
  },
  getTableAssessmentPreviewById: {
    url: "/api/v1/student/assessment/{assessment_id}",
    method: "get",
  },
  getSectionByClassId:{
    url: "/api/v2/class/section/{class_id}",
    method: "get",
  },
  getUnselectedStudents:{
    url: "/api/v2/student/filter/{section_id}/{assessment_id}",
    method: "get",
  },
  // Get Curriculum For School
  getCurriculumSchool: {
    url: "/api/v1/school/content/curriculum",
    method: "get",
  },
  getSchoolSessions: {
    url: "/api/v1/school/content/session/{curriculum_id}",
    method: "get",
  },
  getSchoolVideos: {
    url: "/api/v1/school/content/video/{session_id}",
    method: "get",
  },
  getSchoolAssessments: {
    url: "/api/v1/school/content/assessment/{session_id}",
    method: "get",
  },
  getSchoolPpts: {
    url: "/api/v1/school/content/ppt/{session_id}",
    method: "get",
  },
  getSchoolHandouts: {
    url: "/api/v1/school/content/handout/{session_id}",
    method: "get",
  },
  //
  getFileMetaData: {
    url: "/api/v1/content/upload/metadata/{attachment_id}",
    method: "get",
  },
  getSessionList: {
    url: "/api/v2/session",
    method: "get",
  },
  getStudentSessionList: {
    url: "/api/v2/session/student/{module_id}/{class_id}",
    method: "get",
  },
  getcontentSessionById: {
    url: "/api/v2/session/{session_id}",
    method: "get",
  },
  getPreviewDetails: {
    url: "/api/v2/session/preview/{session_id}",
    method: "get",
  },

  // PHASE-2 To add Sessions
  addSessions: {
    url: "/api/v2/session",
    method: "post",
  },
  updateSessionById: {
    url: "/api/v2/session/{session_id}",
    method: "put",
  },

  getContentBySession: {
    url: "/api/v2/content/{session_id}/{content_type}?school_id={school_id}&class_id={class_id}",
    method: "get",
  },

  createConetent: {
    url: "/api/v2/content",
    method: "post",
  },

  updateConetent: {
    url: "/api/v2/content/{content_id}",
    method: "put",
  },
  recordContentProgress: {
    url: "/api/v2/content/progress/{school_id}",
    method: "put",
  },
};
