import { Component } from "react";
import LayoutWrapper from "../../../LayoutWrapper";
import "../module.scss";
import handoutIcon from "../../../assets/docs.png";
import pptIcon from "../../../assets/ppt.png";
import fileIcon from "../../../assets/ppt.png";
import pdfIcon from "../../../assets/pdf.png";
import DocViewerComp from "../docViewer/docViewer";
import { toast } from "react-toastify";

export class handoutPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("handoutPage");
    this.state = {
      data: [],
      selectedHandout: {},
      sessionDetails: {},
      breadcrum: "",
      current_id: "",
      docs: [],
    };
  }

  componentDidMount() {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    this.fetchData();
    this.getBreadCums();
  }

  async fetchData() {
    try {
      localStorage.setItem("session_id", this.props.urlparams.session_id);

      const { data } = await this.props.api.apiGetContentBySession({
        session_id: this.props.urlparams.session_id,
        content_type: "HANDOUT",
        school_id: localStorage.getItem("school_id") || "",
        class_id: localStorage.getItem("class_id") || "",
      });
      const docs = await Promise.all(
        data.map(async (obj) => {
          const metadataPayload = {
            attachment_id: obj.attach_file,
          };

          const res = await this.props.api.apiGetFileMetaData(metadataPayload);
          console.log("Meta data: ", res);

          const arr = res.file_name.split(".");
          const ext = arr[arr.length - 1];
          obj.ext = ext;

          return {
            uri: `${this.linkGeneration(obj.attach_file)}`,
            fileName: "random",
          };
        })
      );

      console.log("All ppt: ", data);

      this.setState({
        data: data,
        current_id: data[0].attach_file,
        selectedHandout: data[0],
        docs: docs,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  completed(event, box) {
    if (this.props.urlparams.session_id) {
      let payload = {
        class_id: localStorage.getItem("class_id"),
        module_id: localStorage.getItem("module_id"),
        session_id: this.props.urlparams.session_id,
        content_id: box?.content_id,
        progress: event.target.checked ? 100 : 0,
      };
      console.log("event---", payload);
      this.props.api
        .apiRecordConentProgress(
          { school_id: localStorage.getItem("school_id") },
          payload
        )
        .then(({ data, total }) => {
          this.fetchData();
          toast.success("Handout Completion Status Updated", {
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
          });
        });
    }
  }

  routeToSession(e) {
    e.preventDefault();
    e.stopPropagation();
    let closeLink = `/curriculum/sessions?grade=${
      this.props.urlparams.grade
    }&level=${this.props.urlparams.level.toUpperCase()}&type=${
      this.props.urlparams.type
    }&curriculum_id=${
      this.props.urlparams.curriculum_id
    }&class_id=${localStorage.getItem("class_id")}`;

    const lastIndex = closeLink.lastIndexOf("?");
    const correctedCloseLink =
      closeLink.slice(0, lastIndex) + closeLink.slice(lastIndex + 1);
    // console.log("This is close link", correctedCloseLink);
    this.props.navigate(correctedCloseLink);
  }

  getBreadCums() {
    if (this.props.urlparams.session_id) {
      let payload = {
        session_id: this.props.urlparams.session_id,
      };
      console.log(payload);
      this.props.api
        .apiGetContentSessionById({
          session_id: this.props.urlparams.session_id,
        })
        .then(({data}) => {
          // console.log("session data: ", data);
          this.setState({
            sessionDetails: data,
          });
          let breadcrum = `Curriculum/${this.props.urlparams.grade} Grade - Level ${
            this.props.urlparams.level
          }/
        ${this.props.urlparams?.type}/${data.session_name} : Handout`;

          this.setState({
            breadcrum: breadcrum,
          });
        });
    } else {
      return "PPT";
    }
  }

  getBackLink() { 
      let backLink = `/curriculum/sessions?grade=${
        this.props.urlparams.grade
      }&level=${this.props.urlparams.level.toUpperCase()}&type=${
        this.props.urlparams.type
      }&curriculum_id=${
        this.props.urlparams.curriculum_id
      }&class_id=${localStorage.getItem("class_id")}`;
      return backLink; 
  }

  getTitle() {
    if (this.props.urlparams.grade) {
      let title = `${this.props.urlparams.grade} Grade - Level ${this.props.urlparams.level}`;
      return title;
    } else {
      return "Session";
    }
  }

  linkGeneration(attach_file) {
    let authToken = window.store.getState().root.auth;
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: attach_file }).url +
      "?auth=" +
      authToken
    );
  }

  getSelectedHandout(item) {
    let docs = [
      {
        uri: `${this.linkGeneration(item.attach_file)}`,
        fileName: "random",
      },
    ];
    this.setState({
      docs: docs,
      selectedHandout: item,
    });
  }

  render() {
    let {
      data = [],
      selectedHandout,
      sessionDetails,
      breadcrum,
      docs,
    } = this.state;
    console.log("dtatatata", selectedHandout);
    return (
      <LayoutWrapper
        title={this.getTitle()}
        back={breadcrum}
        backlink={this.getBackLink()}
      >
        <div className="card bg-white media_box">
          <div className="card-header">
            <h3 className="title">{sessionDetails.session_name}</h3>
          </div>
          <div className="card-body px-0">
            <div className="row g-4 mx-auto px-0">
              {selectedHandout && (
                <div className="col-md-7">
                  <div className="iframe_container">
                    <DocViewerComp
                      documents={docs}
                      ext={selectedHandout.ext}
                    ></DocViewerComp>
                  </div>
                </div>
              )}
              <div className="col-12 col-md-5">
                <p className="content_description">
                  {sessionDetails.session_description}
                </p>
                <div className="mini_box_container">
                  {data?.map((box, j) => {
                    return (
                      <div className="mini_box" key={j}>
                        <div className="row g-3 m-auto px-0">
                          <p className="title mb-0">{box.handout_name}</p>
                          <div
                            onClick={() => this.getSelectedHandout(box)}
                            className="thumbnail_box"
                            style={{
                              background: "transparent",
                            }}
                          >
                            <img
                              src={
                                box.ext === "pptx" || box.ext === "ppt"
                                  ? pptIcon
                                  : box.ext === "pdf"
                                  ? pdfIcon
                                  : box.ext == "docx" || box.ext == "doc"
                                  ? handoutIcon
                                  : fileIcon
                              }
                              alt=""
                              style={{ height: "100%" }}
                            />
                          </div>
                          {localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR" &&
                          <div className="col-8 d-flex align-items-center justify-content-start">
                            <input
                              type="checkbox"
                              name={`check-complete-${j}`}
                              id={`check-complete-${j}`}
                              className="mx-2"
                              style={{
                                accentColor: "#fba106",
                              }}
                              checked={
                                box.progress === 100 && box?.progress
                                  ? true
                                  : false
                              }
                              onChange={(...args) =>
                                this.completed(...args, box)
                              }
                            />
                            <label for={`check-complete-${j}`}>Completed</label>
                          </div>}
                        </div>
                      </div>
                    );
                  })}
                  {data.length === 0 && <>No handouts found</>}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div
            className="card-footer social_box"
            style={{
              position: "static",
            }}
          >
            {/* {localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR" && <button className="btn add-btn float-none me-3">
              {data[0]?.module_progress?.toFixed(2)} % Completed
            </button>} */}
            {/* <button type="button" className="social_btn">
                  <i class="fa fa-thumbs-o-up"></i>
                  <span>{item.total_likes}</span>
                </button>
                <button type="button" className="social_btn">
                  <i class="fa fa-thumbs-o-down"></i>
                  <span>{item.total_dislikes}</span>
                </button> */}
            {/* <button
              type="button"
              onClick={(...args) => this.handleClose(...args)}
              className="btn add-btn min-wd-100"
            >
              Close
            </button> */}
            <button
              type="button"
              onClick={(...args) => this.routeToSession(...args)}
              className="btn add-btn min-wd-100 mr-2"
            >
              Back To Sessions
            </button>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(handoutPage);
