import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import StudentModal from "./StudentModal";
import TableSearch from "./TableSearch";

export class TableManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("TableManagement");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      editData: {},
      columns: [],
      selectedRowKeys: [],
      // classArr: ["VI", "VII", "VIII", "IX"],
      currentClass: "VI",
      popupfields: [],
      popupdata: {},
      editMode: false,
      sections: [],
      loader: false,
      classId: "",
      studentCredentials: {
        user_id: "",
        otp: "",
      },
      schoolId: window.store.getState().root.school_id,
      tableFields: [
        {
          type: "text",
          name: "school_name",
          label: "School Name",
          required: true,
          disabled: true,
        },
        {
          type: "select",
          name: "curriculum_id",
          label: "Class Name",
          options: [],
          required: true,
          onChange: (e) => this.handleSections(e),
        },
        {
          type: "text",
          name: "table_no",
          label: "Table No.",
          required: true,
        },
        {
          type: "text",
          name: "user_id",
          label: "User ID",
          required: true,
          className: "invoice-user-id",
        },
        {
          type: "text",
          name: "password",
          label: "Password",
          required: true,
          customcolspan: "col-lg-12",
        },
      ],
    };
    this.tableColumns = [
      {
        dataIndex: "curriculum_grade",
        title: "Class",
      },
      {
        dataIndex: "table_no",
        title: "Table No.",
      },
      {
        dataIndex: "user_id",
        title: "User ID",
      },
      {
        dataIndex: "password",
        title: "Password",
      },
    ];

    this.studentModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchTableData();
    this.fetchClassList();
  }

  handleStoreChange = () => {
    const newSchoolId = window.store.getState().root.school_id;
    if (newSchoolId !== this.state.schoolId) {
      this.setState(
        {
          schoolId: newSchoolId,
        },
        () => this.fetchClassList()
      );
    }
  };

  fetchClassList() {
    this.setState({
      loader: true,
    });
    this.props.api
      .apiGetClassById({ school_id: this.state.schoolId })
      .then((response) => {
        let data = response.data[0].records;
        let tableClassNames = this.state.tableFields.find(
          (o) => o.name == "curriculum_id"
        );
        tableClassNames.options = data?.options("curriculum_grade");
        this.setState({
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
        });
      });
  }

  handleSections(data) {
    let classId = data[0]?.class_id;
    this.setState({
      classId: classId,
    });
    this.setState({
      loader: true,
    });
    this.getClassDetails(classId);
  }

  getClassDetails(classId) {
    let payload = {
      class_id: classId,
    };
    this.props.api
      .apiGetClassDetailsById(payload, {
        class_id: classId,
      })
      .then((response) => {
        console.log({ apiGetClassDetailsById: response.data });
        let data = response.data.section;
        let classNames = this.state.studentFields.find(
          (o) => o.name == "section_id"
        );
        classNames.options = data?.options("section_id", "name");
        this.setState({
          studentFields: [...this.state.studentFields],
          loader: false,
        });
      })
      .catch((error) => {
        console.log({ error: error });
        this.setState({
          loader: false,
        });
      });
  }

  fetchList() {
    this.props.api
      .apiGetAllSchoolList()
      .then(({ data }) => {
        this.setState({ loader: false });

        let payload = {
          school_id: localStorage.getItem("school_id"),
        };

        this.props.api
          .apiGetAllStudents(this.state.search, payload)
          .then((res) => {
            this.setState({
              data: res.data,
              rowcount: res.total,
              popupfields: this.state.studentFields,
              loader: false,
            });
          })
          .catch(() => {
            this.setState({
              loader: false,
            });
          });

        this.props.api
          .apiGetClassById(payload)
          .then((response) => {
            let data = response.data[0].records;
            let classNames = this.state.studentFields.find(
              (o) => o.name == "curriculum_id"
            );
            classNames.options = data?.options("curriculum_grade");
            let tableClassNames = this.state.tableFields.find(
              (o) => o.name == "curriculum_id"
            );
            tableClassNames.options = data?.options("curriculum_grade");
          })
          .catch(() => {
            this.setState({
              loader: false,
            });
          });
        this.setState({
          loader: false,
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
  }

  fetchTableData(data) {
    this.setState({
      loader: true,
    });
    let tableSearch = {
      ...this.state.search,
      ...data,
    };
    let school_id = localStorage.getItem("school_id");
    this.props.api
      .apiGetAllTableList(tableSearch, { school_id: school_id })
      .then((response) => {
        this.setState({
          loader: false,
          data: response.data[0].records,
          rowcount: response.data[0].pagination[0]?.totalRecords,
          columns: this.tableColumns,
          popupfields: this.state.tableFields,
        });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loader: false });
      });
  }

  onTableSearch(data) {
    this.fetchTableData(data);
  }

  // DATA FETCHING END

  downloadFile() {}

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 10,
        },
      },
      () => this.fetchTableData()
    );
  }

  // TABLE FUNCTIONALITIES START
  deleteAction(v) {
    confirm(`Do you really want to delete these records?`).promise.then(() => {
      this.api.apiDeleteTableById({ table_id: v.table_id }).then((res) => {
        success("Record Deleted successfully");
        this.fetchTableData();
      });
    });
  }

  editAction(v) {
    console.log("edit: ", v);
    let school_name = localStorage.getItem("school_name");
    this.openStudentModal();

    const updatedEditFields = [...this.state.tableFields];

    const fieldsToDisable = ["curriculum_id"];
    fieldsToDisable.forEach((fieldName) => {
      const fieldIndex = updatedEditFields.findIndex(
        (field) => field.name === fieldName
      );
      if (fieldIndex !== -1) {
        updatedEditFields[fieldIndex] = {
          ...updatedEditFields[fieldIndex],
          disabled: v.curriculum_grade ? true : false,
        };
      }
    });
    const fields = (updatedEditFields || []).reduce((o, n) => {
      o[n.name] = n;
      return o;
    }, {});
    let popupdata = {
      school_name: school_name,
      curriculum_id: v.curriculum_grade,
      table_no: v.table_no,
      user_id: v.user_id,
      password: v.password,
      // classId: v.class_id,
    };
    this.setState({
      popupfields: fields,
      popupdata: popupdata,
      editData: v,
      editMode: true,
      viewMode: false,
    });
  }

  viewAction(v) {
    console.log("view: ", v);

    let school_name = localStorage.getItem("school_name");
    this.openStudentModal();
    let popupdata = {
      school_name: school_name,
      curriculum_id: v.curriculum_grade,
      section_id: v.section_id,
      student_name: v.name,
      email: v.email,
      user_id: v.user_id,
      profile_id: v.profile_id,
    };
    this.getClassDetails(v.class_id);
    this.setState((prevState) => ({
      popupdata: popupdata,
      editData: v,
      editMode: false,
      viewMode: true,
    }));
  }
  // TABLE FUNCTIONALITIES END

  // MODAL FUNCTIONS START
  openStudentModal() {
    this.studentModalRef.current.click();
  }

  closeStudentModal() {
    document.querySelector("#Student_modal #bsmodalclose").click();
  }
  addModalFields(values, modalfields) {
    console.log({ values, modalfields });
    let fields;

    fields = (modalfields || []).reduce((o, n) => {
      o[n.name] = n;
      return o;
    }, {});

    const formdata = Object.keys(fields).reduce((o, k) => {
      o[k] = "";
      return o;
    }, {});
    formdata["school_name"] = localStorage.getItem("school_name");
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }
  // MODAL FUNCTIONS START

  handleClassChange(e, data = [...this.state.data]) {
    localStorage.setItem("currentClass", e);
    let filteredData = data.filter((item) => item.curriculum_grade === e);
    console.log("filteredData", filteredData);
    this.setState(
      {
        currentClass: e,
      },
      () => {
        this.fetchTableData();
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    let formData = e.target.getData();
    console.log("formData", this.state.editData);
    let school_id = localStorage.getItem("school_id");

    if (!this.state.editMode) {
      let payload = {
        school_id: localStorage.getItem("school_id"),
        class_id: this.state.classId,
        table_no: formData?.table_no,
        user_id: formData?.user_id,
        password: formData?.password,
      };
      this.setState({
        loader: true,
      });
      this.props.api
        .apiAddTableData(payload)
        .then((res) => {
          success("Table added successfully");
          this.closeStudentModal();
          this.fetchTableData();
          this.setState({
            loader: false,
          });
        })
        .catch((error) => {
          if (error?.response?.data?.issues?.message) {
            toast.error(error?.response?.data?.issues?.message, {
              autoClose: 3000,
              closeButton: false,
              hideProgressBar: true,
            });
          } else {
            this.closeStudentModal();
          }
          this.setState({
            loader: false,
          });
        });
    } else {
      let payload = {
        school_id: localStorage.getItem("school_id"),
        // class_id: this.state.editData.class_id,
        table_no: formData?.table_no,
        user_id: formData?.user_id,
        password: formData?.password,
      };
      this.setState({
        loader: true,
      });
      this.props.api
        .apiEditTable(payload, { table_id: this.state.editData.table_id })
        .then((res) => {
          console.log("apiAddSchoolMoke: ", res);
          success("Table Edited successfully");
          this.closeStudentModal();
          this.fetchTableData();
          this.setState({
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
        });
    }
  }
  onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    console.log({ selectedRowKeys, selectedRows });
    this.setState({
      selectedRowKeys: selectedRows.map((row) => row.table_id),
    });
  };

  onGeneratePassword=()=>{
    this.setState({
      loader: true,
    });
    this.props.api
    .apiUpdateTablePasswords({table_ids:this.state.selectedRowKeys}).then((res) => {
      toast.success("Passwords Generated successfully");
      this.fetchTableData();
      this.setState({
        loader: false,
        selectedRowKeys:[]
      });
    })
    .catch((err) => {
      toast.error("Error while generating passwords");
      this.setState({
        loader: false,
      });
    });
  }

  render() {
    let {
      data = [],
      rowcount,
      columns,
      viewMode,
      popupfields,
      editMode,
      popupdata,
      editData,
      loader,
      selectedRowKeys,
      tableFields,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col"></div>
              <div className="mb-4">
                <Link
                  className="btn add-btn"
                  ref={this.studentModalRef}
                  data-bs-toggle="modal"
                  data-bs-target="#Student_modal"
                  onClick={(...args) => {
                    this.addModalFields(...args, tableFields);
                  }}
                >
                  <i className="fa fa-plus"></i>
                  Add Table
                </Link>
              </div>
            </div>
            <TableSearch
              onTableSearch={(...arg) => this.onTableSearch(...arg)}
            />
            <div className="row ClassManagement">
              <div className="col-12">
                {!!data.length && (
                  <AppTable
                    data={data}
                    columns={columns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    pageSize={50}
                    deleteAction={(v) => this.deleteAction(v)}
                    editAction={(v) => this.editAction(v)}
                    viewAction={(v) => this.viewAction(v)}
                    deletable={true}
                    viewable={false}
                    checkbox={true}
                    rowSelection={rowSelection}
                    isCustomRowKey={true}
                    customRowKey={"table_id"}
                    targetType="tap"
                  ></AppTable>
                )}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>
            <StudentModal
              id="Student_modal"
              title={`${editMode ? "Update" : viewMode ? "View" : "Add"} Table`}
              submitText={`${editMode ? "Update" : "Submit"}`}
              data={popupdata}
              fields={popupfields}
              disablefields={["Curriculum Grade"]}
              disableallfields={viewMode}
              editData={editData}
              submitButton={viewMode ? false : true}
              closeButton={false}
              resetPassword={true}
              onSubmit={(...args) => {
                if (args[1].password.length < 8) {
                  alert("Password should be minimum 8 characters");
                  return;
                }
                this.handleSubmit(...args);
              }}
              onClose={() =>
                this.setState({
                  popupdata: {},
                  editData: {},
                  editMode: false,
                  viewMode: false,
                })
              }
            />
          </div>

          <button
            id="fetch_class"
            className="btn btn-sm btn-outline-secondary d-none"
            onClick={() => this.handleStoreChange()}
          >
            <span>Hidden Button</span>
          </button>
          <div className="row bg-white fixed bottom-0 justify-content-end p-2">
            <div className="col-sm-6 col-md-2 p-1">
              <button
                className="btn btn-success btn-block w-100"
                onClick={() => this.onGeneratePassword()}
              >
                Generate Password
              </button>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(TableManagement);
