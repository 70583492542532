export default {
    //attendance
    getAttendanceBySectionIdForAdmin: {
      url: "/api/v2/attendance/{section_id}/{month_year}",
      method: "get",
    },
    getExistingAttendanceBySectionId: {
      url: "/api/v2/attendance/existing/{section_id}/",
      method: "get",
    },
    updateAttendance: {
        url: "/api/v2/attendance/",
        method: "post",
      },
  };