import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LayoutWrapper from "../LayoutWrapper";
import _enum from "src/mixins/enum";

export class EditEvent extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("EditEvent");
    this.state = {
      data: [],
      editInfo: null,
      event: [{ school_id: "", comment: "" }],
      classes: [],
      sections: [],
      nonEditableFields: [
        {
          type: "date",
          name: "createdAt",
          label: "Created Date",
        },
        {
          type: "text",
          name: "created_by",
          label: "Created By",
        },
        {
          type: "date",
          name: "updatedAt",
          label: "Last Updated Date",
        },
        {
          type: "text",
          name: "last_updated_by",
          label: "Last Updated By",
        },
      ],
      fields: [
        {
          type: "select",
          name: "school_id",
          label: "School",
          placeholder: "Select School",
          disabled: true,
          options: [],
          onChange: (data) => {
            this.handleSchoolChange(data?.[0]?.school_id);
          },
          required: true,
        },
        {
          type: "date",
          name: "event_date",
          label: "Date",
          disabledDates: (date) => {
            return date.getTime() < new Date().toDate().date().getTime();
          },
          onChange: (data) => {
            this.handleEventDateChange(data);
          },
          required: true,
        },
        {
          type: "time",
          name: "event_time",
          label: "Time",
          onChange: (e) => {
            this.handleTimeChange(e);
          },
          required: true,
        },
        {
          type: "select",
          name: "class_id",
          label: "Class",
          options: [],
          required: true,
          onChange: (data) => {
            this.getSectionsByClassId(data?.[0]?.class_id);
          },
        },
        {
          type: "select",
          name: "section_id",
          label: "Section",
          options: [],
          // onChange: (data) => {
          //   this.getTopics(data?.[0]?.section_id);
          // },
          required: true,
        },
        {
          type: "select",
          name: "topic",
          label: "Topic",
          fieldclass: "col-6",
          options: [],
          required: true,
        },
        {
          type: "select",
          name: "event_status",
          label: "Status",
          options: _enum.EVENT_STATUS,
          required: true,
          onChange: (data) => {
            this.handleEventStatusChange(data);
          },
        },
        {
          type: "textarea",
          name: "comment",
          label: "Comments",
          fieldclass: "col-6",
        },
      ],
      recurrenceInfo: null,
      loader: false,
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    let event_id = JSON.parse(sessionStorage.getItem("eventData"));
    this.props.api
      .apiGetSingleEvent({ event_id: event_id })
      .then(({ data }) => {
        let info = {
          ...data,
          created_by: data?.created?.name,
          last_updated_by: data?.updated_by?.name,
        };
        this.setState({
          editInfo: info,
        });
        this.getSchoolNames();
      })
      .catch((error) => {
        toast.error("Error while fetching event");
        this.props.navigate("/schedulemanagement");
      });
  }

  handleEventDateChange = (data) => {
    this.setState({
      recurrenceInfo: {
        ...this.state.recurrenceInfo,
        start_occurance_date: data,
      },
    });
  };
  handleTimeChange = (e) => {
    this.setState({
      recurrenceInfo: {
        ...this.state.recurrenceInfo,
        event_time: e.target.value,
      },
    });
  };
  getSchoolNames = async () => {
    try {
      const { data } =
        localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR"
          ? await this.props.api.apiGetAllSchoolList()
          : await this.props.api.apiGetAllSchools({
              limit: 50,
              page: 1,
              order_by: "updatedAt",
              order_direction: "DESC",
            });

      let field = this.state.fields.find((o) => o.name === "school_id");
      let opt =
        localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR"
          ? data
          : data?.[0].records;
      field.options = opt?.options("school_id", "school_name");
      this.setState({
        ...this.state.fields,
      });
    } catch (error) {
      console.log({ error: error });
    }
  };

  handleSchoolChange(school_id) {
    this.getClassesBySchool(school_id);
    this.getTopics(school_id);
  }
  getClassesBySchool(school_id) {
    this.props.api
      .apiGetClassById({ school_id: school_id })
      .then(({ data }) => {
        let field = this.state.fields.find((o) => o.name === "class_id");
        field.options = data?.[0].records?.options(
          "class_id",
          "curriculum_name"
        );
        this.setState({
          fields: this.state.fields,
          classes: data?.[0].records,
        });
      })
      .catch((error) => {});
  }

  getSectionsByClassId(classId) {
    let field = this.state.fields.find((o) => o.name === "section_id");
    let classes = this.state.classes.find((r) => r.class_id === classId);
    field.options = classes.section?.options("section_id", "name");
    this.setState({
      ...this.state.fields,
      sections: classes.section,
    });
  }

  handleEventStatusChange(val) {
    let value = val[0]?.key
    let field = this.state.fields.find((o) => o.name === "comment");
    ["CANCELLED", "COMPLETED"].includes(value) ? field.required = true : field.required = false;
    this.setState({
      ...this.state.fields,
    });
  }

  getTopics(school_id) {
    let payload = {
      school_id: school_id,
    };
    if (localStorage.getItem("currentAdminRole") == "SCHOOL_COORDINATOR") {
      payload.school_id = localStorage.getItem("school_id");
    }

    this.props.api
      .apiGetAccessedSessions(payload)
      .then(({ data }) => {
        // this.setState({
        //   sessionsData: res.data[0].records,
        //   loader: false,
        // });
        let field = this.state.fields.find((o) => o.name === "topic");
        field.options = data?.options("session_id", "session_name");
        // field.options = data?.find("session_id", "session_name");
        this.setState({
          fields: this.state.fields,
        });
      })
      .catch(() => {
        this.setState({ loader: false });
      });
  }

  submitForm(e, data) {
    e.preventDefault();
    this.setState({
      loader: true,
    });
    let parsedPayload = parseInput(data) || [];
    for (let key in parsedPayload) {
      if (parsedPayload[key] === "") {
        delete parsedPayload[key];
      }
    }
    this.props.api
      .apiUpdateEventSuper(
        { event_id: this.state.editInfo?.event_id },
        parsedPayload
      )
      .then((response) => {
        toast.success("Event Updated successfully");
        this.props.navigate("/schedulemanagement");
        this.setState({
          loader: false,
        });
      })
      .catch(({ response }) => {
        toast.error(
          response?.data?.issues?.message || "Error while updating event"
        );
        this.props.navigate("/schedulemanagement");

        this.setState({
          loader: false,
        });
      });
  }

  render() {
    let { data, loader, fields, nonEditableFields, editInfo } = this.state;
    return (
      <LayoutWrapper
        title="Schedule Management"
        back="Schedule Management / Edit Event"
        backlink="/schedulemanagement"
      >
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        {/* <ToastContainer position="top-right" /> */}
        <div className="row m-auto flex flex-col gap-4 bg-white p-4">
          <div className="row align-items-center">
            {nonEditableFields?.map((field, i) => (
              <div
                className={
                  field.fieldclass
                    ? field.fieldclass
                    : "col-12 col-md-6 col-lg-3"
                }
              >
                <AppInput
                  // type={field.type}
                  {...field}
                  name={field.name}
                  disabled={true}
                  // label={field.label}
                  defaultValue={editInfo?.[field.name]}
                ></AppInput>{" "}
              </div>
            ))}
          </div>
          <hr />
          <AppForm
            ref={this.form}
            // className="flex flex-col gap-4 bg-white p-4"
            onSubmit={(...args) => this.submitForm(...args)}
          >
            <div className="row align-items-center">
              {fields?.map((field, i) => (
                <>
                  <div
                    className={
                      field.fieldclass
                        ? field.fieldclass
                        : "col-12 col-md-6 col-lg-3"
                    }
                  >
                    <AppInput
                      // type={field.type}
                      {...field}
                      name={field.name}
                      // label={field.label}
                      defaultValue={editInfo?.[field.name]}
                      disabled={this.props.urlparams.readonly === "true" || field.disabled ? true : false}
                    ></AppInput>{" "}
                  </div>
                </>
              ))}
            </div>
            <div className="row no-gutters">
              <Link
                to="/schedulemanagement"
                className="btn btn_primary col col-md-1 ml-auto grey"
              >
                Cancel
              </Link>
              <button
                className={Object.className({
                  "btn add-btn col col-md-1 ml-auto ms-2 me-2": true,
                  "d-none": this.props.urlparams.readonly === "true",
                })}
                type="submit"
              >
                Update
              </button>
            </div>
          </AppForm>
        </div>
      </LayoutWrapper>
    );
  }
}

export default connect(EditEvent);
