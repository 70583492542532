import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import Sidebar from "./sidebar";
import "antd/dist/antd.min.css";
import "./curriculum.css";

export const BreadCum = (props) => (
  <div className="col">
    <h3 className="page-title">{props.title}</h3>
    <ul className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to={props.backlink}>{props.back}</Link>
      </li>
      <li className="breadcrumb-item active">{props.title}</li>
    </ul>
  </div>
);

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { menu: false };
    window.alert = this.props.mixin.alert;
    window.loader = this.props.mixin.loader;
    window.confirm = this.props.mixin.confirm;
    window.prompt = this.props.mixin.prompt;
    window.success = this.props.mixin.success;
    window.error = this.props.mixin.error;
  }

  toggleMobileMenu = () => {
    this.setState({ menu: !this.state.menu });
  };
  render() {
    let props = this.props;
    return (
      <>
        <div className={`main-wrapper ${this.state.menu ? "slide-nav" : ""}`}>
          <Header onMenuClick={(value) => this.toggleMobileMenu()} />
          <Sidebar />
          <div className="page-wrapper">{this.props.children}</div>
        </div>
        {(props.store.requests || []).map((request, i) => (
          <AppModal
            active={!!request.message}
            footer={["alert", "confirm", "prompt"].includes(request.type)}
            key={i}
            {...request}
            onClose={() => mixins.popRequest(request.id)}
            onCancel={() => mixins.popRequest(request.id)}
          >
            <div className="min-w-[300px] text-center break-word">
              <i className="animate-spin pi pi-fw pi-spinner h-3 w-3"></i>
              <div>{request.message}</div>
            </div>
          </AppModal>
        ))}
      </>
    );
  }
}

export default connect(AdminLayout);
