import moment from "moment";
import React, { Component } from "react";
import AttendanceSearch from "./AttendanceSearch";

export class AttendanceDetailsAdmin extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AttendanceDetailsAdmin");
    this.state = {
      loader:false,
      monthDate: new Date(),
      attendanceData: [],
      employeeDataObj: [],
      search: {
        page: 1,
        month_year: `${new Date().getFullYear()}-${String(
          new Date().getUTCMonth() + 1
        ).padStart(2, "0")}`,
      },
    };
    this.columns = [];
    this.setColumns();

    this.colors = {
      late_punch: "text-yellow-500",
      present: "text-green-500",
      absent: "text-red-500",
      leave: "text-orange-500",
      holiday: "text-primary",
      sunday: "text-gray-300",
    };
  }
  componentDidMount() {
    window.LeaveRequests = this;
    this.fetchData();
  }
  get daylist() {
    let { monthDate = new Date() } = this.state;
    return new Date(monthDate).dateList().map((o) => ({
      name: (
        <div>
          <div className="font-bold text-center">{o.getDate()}</div>
          <div
            className={Object.className({
              "text-sm text-center": true,
              "text-red-500": o.getDayName() == "SUN",
              "text-gray-500": o.getDayName() != "SUN",
            })}
          >
            {o.getDayName().toLowerCase().toTitleCase()}
          </div>
        </div>
      ),
      key: o.getDate(),
    }));
  }

  get monthDays() {
    let dayListArr = Object.entries(this.daylist);
    return dayListArr.map(([i, daynum]) => ({ key: daynum.key, index: i }));
  }

  renderIntime(obj) {
    if (obj.in_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else {
      return obj.in_time;
    }
  }
  renderOuttime(obj) {
    if (obj.in_time === obj.out_time) {
      return "-";
    } else if (obj.in_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else if (obj.out_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else {
      return obj.out_time;
    }
  }
  renderStatus(sts, record) {
    sts = sts.toLowerCase();
    if (sts === "present") {
      return <b className={`${this.colors[sts]} text-capitalize`}>{sts}</b>;
    }
    if (sts === "absent") {
      return (
        <b className={`${this.colors["absent"]} text-capitalize`}>{sts}</b>
      );
    }
  }
  timeDiff(start, end) {
    start = start.split(":");
    end = end.split(":");
    if (Number(start[0]) > Number(end[0])) {
      var num = Number(start[0]);
      var countTo = Number(end[0]);
      var count = 0;
      for (var i = 1; num != countTo; ) {
        num = num + i;
        if (num > 24) {
          num = 0;
        }
        count++;
      }
      var hours = count - 1;
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      if (startDate.getMinutes() > endDate.getMinutes()) {
        var hours = count - 2;
        var diff = 60 - (startDate.getMinutes() - endDate.getMinutes());
      } else {
        var diff = endDate.getMinutes() - startDate.getMinutes();
      }
      var minutes = diff;
    } else {
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
    }

    if (isNaN(hours)) {
      return "-";
    }
    var returnValue =
      (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
    let mStr = moment(returnValue, "HH:mm:ss").format("hh:mm");
    let formattedTime =
      mStr.split(":")[0] + " hrs" + " " + mStr.split(":")[1] + " mins";
    returnValue = formattedTime;
    return returnValue;
  }
  setColumns() {
    let { daylist, monthDays } = this;
    let year = this.state.monthDate.getFullYear();
    let month = this.state.monthDate.getMonth();
    this.colors = {
      present: "text-green-500",
      absent: "text-red-500",
      leave: "text-orange-500",
      holiday: "text-primary",
      sunday: "text-gray-300",
    };
    this.status = Object.map(this.colors, (value, key) => {
      switch (key) {
        case "leave":
          return <b className={`${this.colors[key]}`}>L</b>;
        case "holiday":
          return <b className={`${this.colors[key]}`}>H</b>;
        case "sunday":
          return <i className={`fa fa-circle ${this.colors[key]} text-xs`}></i>;
        case "absent":
          return <i className={`fa fa-close ${this.colors[key]}`}></i>;
        default:
          return <i className={`fa fa-check ${this.colors[key]}`}></i>;
      }
    });
    this.columns = [
      {
        dataIndex: "name",
        title: "Name",
      },
      {
        dataIndex: "id",
        display: false,
      },
      {
        dataIndex: "profile_id",
        display: false,
      },
      ...monthDays.map(({ key, index }) => ({
        dataIndex: key,
        title: daylist[index]?.name,
        sorter: false,
        render: (text, record) => {
          let dateobj = new Date(year, month, key);
          // let holidaydate = app.props.store.holidayList.find(
          //   (o) => new Date(o.holiday_date).toDate() == dateobj.toDate()
          // );
          return (
            <div className="text-center" title={""}>
              {this.status[text] || this.status["absent"]}
            </div>
          );
        },
      })),
    ];
  }

  onSearch(data) {
    // console.log("onSearch data: ", data);
    if (data.month_year) {
      data.month_year = new Date().formatToYYYYMM(data.month_year);
    }
    this.setState(
      {
        search: {
          ...this.state.search,
          ...Object.except(data, ["class_id", "school_id"]),
        },
      },
      () => {
        this.fetchData();
      }
    );
  }

  fetchData(extra = {}) {
    this.setState({
      loader: true,
    });
    let payload = {
      ...this.state.search,
    };
    this.props.api
      .apiGetAttendanceBySectionIdForAdmin(payload)
      .then(({ data }) => {
        this.setState({
          attendanceData: data,
          loader: false,
        });
      })
      // .catch((error) => {
      //   this.setState({
      //     loader: false,
      //   });
      // });
  }

  onPrev() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  onNext() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    let { loader } = this.state;
    let { links, columns = [] } = this;
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <AttendanceSearch onSearch={(...arg) => this.onSearch(...arg)} />
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={this.state.attendanceData}
              // data={dummyResData}
              columns={columns}
              editable={false}
              deletable={false}
              reorder={true}
              isRowDeletable={() => false}
              isRowEditable={() => false}
              viewable={false}
              onNext={() => this.onNext()}
              onPrev={() => this.onPrev()}
              total={this.state.rowcount}
              pageSize={10}
            ></AppTable>
          </div>
        </div>
      </>
    );
  }
}

export default connect(AttendanceDetailsAdmin);
