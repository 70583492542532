import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import PillarCard from "./PillarCard";
import { Link } from "react-router-dom";

export class PillarsPage extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("PillarsPage");
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.fetchList();
    localStorage.setItem("class_id", this.props.urlparams.class_id);
  }

  fetchList() {
    this.setState({ loader: true });

    let school_id = localStorage.getItem("school_id");
    let class_id = this.props.urlparams.class_id == "undefined" ? localStorage.getItem("class_id") : this.props.urlparams.class_id;
    this.props.api
      .apiGetAllModules({
        school_id: school_id,
        class_id: class_id || "",
      })
      .then((response) => {
        let authToken = window.store.getState().root.auth;
        let result = response.data[0].records.map((item) => ({
          ...item,
          bgImg:
            app.api
              .request(app.apis().fileDownloader)
              .urltransform({ attachmentId: item?.attachment_id }).url +
            "?auth=" +
            authToken,
        }));

        this.setState({
          data: result,
          loader: false,
        });
        // this.downloadFile(response.data[1].attachment_id);
      })
      .catch(() => {
        this.setState({ loader: false });
      });
  }

  downloadFile(fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken, fileId });
    const apiUrl =
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var imageUrl = URL.createObjectURL(blob);
        console.log(imageUrl, "link");
        window.URL.revokeObjectURL(imageUrl);
      })
      .catch((error) => console.error("Error downloading file:", error));
  }

  changeLevel(value) {
    localStorage.setItem("module_id", value.module_id);
    let nextLink =
      "/contentmanagement/sessions" +
      Object.encodeuri({
        grade: this.props.urlparams.grade,
        level: this.props.urlparams.level,
        curriculum_id: this.props.urlparams.curriculum_id,
        class_id: this.props.urlparams.class_id,
      }) +
      "&type=" +
      value?.module_name;
    // "&type=" +
    // value?.key?.snakeCase().toLowerCase() +
    // "&curriculum_id=" +
    // this.props.urlparams.curriculum_id;
    // "&class_id=" +
    // this.props.urlparams.class_id;
    this.props.navigate(nextLink);
  }

  getTitle() {
    if (this.props.urlparams.grade) {
      return `${this.props.urlparams.grade} Grade - Level ${this.props.urlparams.level}`;
    } else {
      return `${
        localStorage.getItem("currentAdminRole") != "SUPER_ADMIN"
          ? "Content Delivery"
          : "Content Management"
      } / Pillars`;
    }
  }
  getBreadCums() {
    if (this.props.urlparams.grade) {
      return `${
        localStorage.getItem("currentAdminRole") != "SUPER_ADMIN"
          ? "Content Delivery"
          : "Content Management"
      } / ${this.props.urlparams.grade} Grade - Level ${
        this.props.urlparams.level
      }`;
    } else {
      return `${
        localStorage.getItem("currentAdminRole") != "SUPER_ADMIN"
          ? "Content Delivery"
          : "Content Management"
      } / Pillars`;
    }
  }

  render() {
    let { data } = this.state;
    return (
      <LayoutWrapper
        title={this.getTitle()}
        back={this.getBreadCums()}
        backlink="/contentmanagement"
      >
        <div className="row"></div>
        {localStorage.getItem("currentAdminRole") != "SCHOOL_COORDINATOR" ? (
          <Row className="mx-0 mb-3 g-3">
            {data?.map((item, i) => {
              return (
                <Col className="col-12 col-lg-4 col-xxl-3" key={item}>
                  <a onClick={() => this.changeLevel(item)}>
                    <PillarCard
                      title={item.module_name}
                      percent={item.percent}
                      totalSessions={item.totalSessions}
                      description={item.description}
                      bgImg={item.bgImg}
                    />
                  </a>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Row className="mx-0 mb-3 g-3">
            {data?.map((item, i) => {
              return (
                <Col className="col-12 col-lg-4 col-xxl-3" key={item}>
                  <a onClick={() => this.changeLevel(item)}>
                    <PillarCard
                      title={item.module_name}
                      percent={item.average_progress}
                      totalSessions={item.totalSessions}
                      description={item.description}
                      bgImg={item.bgImg}
                      completed={true}
                    />
                  </a>
                </Col>
              );
            })}
          </Row>
        )}
      </LayoutWrapper>
    );
  }
}

export default connect(PillarsPage);
